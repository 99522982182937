export const ADD = 'ADD';
export const EDIT = 'EDIT';
export const COPY = 'COPY';
export const DETAIL = 'DETAIL';

// 能力测评options
export const ABILITY_OPTIONS = {
  operationType: {
    CREATE: '创建',
    EDIT: '编辑',
    PREVIEW: '预览',
    STOP_PREVIEW: '停止预览',
    RELEASE: '发布',
    ARCHIVE: '下架',
    COMPLETE: '完成',
    COPY: '复制',
    DELETE: '删除'
  }
};

/**
 * 富文本通用配置
 * @params isReadonly 是否禁用
 */
export function EDITOR_CONFIG({ isReadonly = false }) {
  return {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 200,
    // 初始容器宽度
    initialFrameWidth: '100%',
    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    serverUrl: '/api/application/ueditor/config',
    catchRemoteImageEnable: true,
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: '/ueditor/',
    maximumWords: 2000, // 允许最大字符
    readonly: isReadonly, // 是否禁用
    toolbars: [
      [
        'fullscreen',
        // 'source', // 源代码
        '|',
        'undo', // 撤销
        'redo', // 重做
        'bold',
        'italic',
        'underline',
        // 'fontborder', // 字符边框
        'strikethrough',
        // 'superscript', // 上标
        // 'subscript', // 下标
        'removeformat', // 清除格式
        // 'formatmatch', // 格式刷
        // 'autotypeset', // 自动排版
        // 'blockquote', // 引用
        // 'pasteplain', // 纯文本模式粘贴
        'forecolor',
        'backcolor',
        // 'insertorderedlist',
        'insertunorderedlist',
        // 'selectall', // 全选
        // 'cleardoc', // 清空文档
        'fontfamily',
        'fontsize',
        // 'simpleupload', // 图片上传
        // 'insertimage', // 多图上传
        // 'emotion', // 表情
        // 'scrawl', // 涂鸦
        // 'music', // 音乐
        // 'insertvideo', // 视频
        // 'insertaudio', // 音频
        // 'attachment', // 附件
        // 'map', // 百度地图
        // 'rowspacingtop', // 段前距
        // 'rowspacingbottom', // 段后距
        'lineheight' // 行间距
        // 'link',
        // 'unlink'
      ]
    ],
    fontfamily: [
      { label: '', name: 'songti', val: '宋体,SimSun' },
      { label: '仿宋', name: 'fangsong', val: '仿宋,FangSong' },
      { label: '仿宋_GB2312', name: 'fangsong', val: '仿宋_GB2312,FangSong' },
      { label: '', name: 'kaiti', val: '楷体,楷体_GB2312, SimKai' },
      { label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei' },
      { label: '', name: 'heiti', val: '黑体, SimHei' },
      { label: '', name: 'lishu', val: '隶书, SimLi' },
      { label: '', name: 'andaleMono', val: 'andale mono' },
      { label: '', name: 'arial', val: 'arial, helvetica,sans-serif' },
      { label: '', name: 'arialBlack', val: 'arial black,avant garde' },
      { label: '', name: 'comicSansMs', val: 'comic sans ms' },
      { label: '', name: 'impact', val: 'impact,chicago' },
      { label: '', name: 'timesNewRoman', val: 'times new roman' }
    ]
  };
}
