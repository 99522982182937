




import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
    components: {}
})
export default class CardLoading extends Vue {
    public isLoading: any = null;

    public mounted(): void {
        this.$nextTick(() => {
            this.handleLoading();
        })
    }
    public destroyed(): void {
        this.isLoading.close();
    }

    public handleLoading() {
        this.isLoading = this.$loading({
            target: "#cardLoading",
            lock: false,
            fullscreen: false,
        });
    }
}
