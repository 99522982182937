


































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ADD, EDIT, DETAIL, EDITOR_CONFIG } from '@/config/constant';
import CardLoading from '@/components/CardLoading/index.vue';
import SubManageDialog from './SubManageDialog.vue';
import { TypeManagementService } from '@/services/abilityAssessment/type-management-service';
import { deepCopy, addRandomKey, extractPlainText } from '@/services/utils';
import { isEmpty, map, filter } from 'lodash';

@Component({
    components: { CardLoading, SubManageDialog },
    filters: {
        resolveRichText: (richText: string | null) => richText ? extractPlainText(richText) : ""
    }
})
export default class ManageDialog extends Vue {
    @Prop(String) private dialogType!: string;
    @Prop() private defaultData: object | null;
    @Emit() cancel(isRefresh: boolean) {
        return isRefresh;
    }
    @Inject(TypeManagementService)
    private typeManagementService!: TypeManagementService;

    public ADD: string = ADD;
    public EDIT: string = EDIT;
    public DETAIL: string = DETAIL;
    public EDITOR_CONFIG: any = EDITOR_CONFIG({});
    public formInfo: any = {
        title: '',
        description: ''
    };
    public rules: any = {
        title: [
            { required: true, message: '请输入测评主类型', trigger: 'blur' },
            { max: 100, message: '长度不能超过 100 个字', trigger: ["change", "blur"] },
        ],
    };
    public labelPosition: String = 'right';
    public title: string = '';
    public tableData: any[] = [];
    public loading: Boolean = false;
    public multipleSelectionKey: any[] = [];
    public visible: boolean = true;
    public isLocked: boolean = false;
    public cardLoading: boolean = false;
    // 子Dialog相关
    public subDialogVisible: boolean = false;
    public subDialogType: string = '';
    public subCurrentIndex: number = -1;
    public subCurrentRow: any = null;

    // 富文本配置
    get TYPE_EDITOR_CONFIG() {
        return EDITOR_CONFIG({ isReadonly: this.isLocked });
    }

    public created(): void {
        this.initData();
    }
    // 清空数据
    public clearData() {
        this.subDialogType = '';
        this.subCurrentRow = null;
        this.subCurrentIndex = -1;
        this.multipleSelectionKey = [];
        (this.$refs.multipleTable as any).clearSelection();
    }
    // 初始化数据
    public async initData() {
        switch (this.dialogType) {
            case ADD:
                this.title = '新增测评类型';
                break;
            case EDIT:
                this.title = '编辑测评类型';
                // 初始化表单和table
                this.formInfo = deepCopy(this.defaultData);
                this.tableData = [];
                if (this.formInfo.subTypes && this.formInfo.subTypes.length) {
                    this.tableData = addRandomKey(this.formInfo.subTypes);
                }
                // check：如果该主类型已关联测评单且测评单已发布，则不允许做任何修改
                this.cardLoading = true;
                const { id } = this.defaultData as any;
                this.isLocked = await this.typeManagementService.checkIsAssociated({ typeId: id });
                this.cardLoading = false;
                break;
        }
    }
    // 多选
    public handleSelectionChange(val) {
        this.multipleSelectionKey = val.map(t => t.key);
    }
    // 新增/编辑/查看
    public async handManage(type: string, idx: number, row?: any) {
        if (type === ADD) {
            this.subCurrentIndex = -1;
            this.subCurrentRow = null;
        } else if (type === EDIT) {
            this.subCurrentIndex = idx;
            this.subCurrentRow = deepCopy(row);
        }
        this.subDialogType = type;
        this.subDialogVisible = true;
    }
    /**
     * 前端逻辑删除
     * @param type 1 单条 2 多条
     * @param idx 单条时的索引
    */
    public logicDelete(type: number, idx?: number) {
        if (type === 1) {
            // 执行前端单条逻辑删除
            this.tableData.splice(idx, 1);
            this.$message({
                message: '删除成功',
                type: 'success'
            });
        } else {
            // 执行前端多条逻辑删除
            this.tableData = this.tableData.filter(
                t => !this.multipleSelectionKey.includes(t.key)
            );
            this.multipleSelectionKey = [];
            this.$message({
                message: '删除成功',
                type: 'success'
            });
        }
    }
    // 删除
    public async handDelete(row: any, idx: number): Promise<void> {
        try {
            await this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            /**
             * 删除校验
             * 新增子类型(无id)：可直接删除
             * 编辑子类型(有id)：调接口判断是否关联了测评项，未关联的才允许删除
             * 编辑子类型(无id)：可直接删除
            */
            switch (this.dialogType) {
                case ADD:
                    this.logicDelete(1, idx);
                    break
                case EDIT:
                    if (row.id) {
                        // check子类型是否关联了测评项
                        const params = {
                            ids: [row.id]
                        }
                        let res = await this.typeManagementService.itemRemoveCheck(params);
                        if (res) {
                            this.logicDelete(1, idx);
                        }
                    } else {
                        this.logicDelete(1, idx);
                    }
                    break
            }
        } catch (e) {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        }
    }
    // 批量删除
    public async handBatchDelete(): Promise<void> {
        try {
            await this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            /**
             * 删除校验
             * 新增子类型(无id)：可直接删除
             * 编辑子类型(有id)：调接口判断是否关联了测评项，未关联的才允许删除
             * 编辑子类型(无id)：可直接删除
            */
            switch (this.dialogType) {
                case ADD:
                    this.logicDelete(2);
                    break
                case EDIT:
                    let ids = this.tableData
                        .filter(t => this.multipleSelectionKey.includes(t.key))
                        .map(t => t.id)
                        .filter(t => t);
                    if (ids.length) {
                        // check子类型是否关联了测评项
                        let res = await this.typeManagementService.itemRemoveCheck({ ids });
                        if (res) {
                            this.logicDelete(2);
                        }
                    } else {
                        // 勾选项都为新增项时
                        this.logicDelete(2);
                    }
                    break
            }
        } catch (e) {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        }
    }
    // 保存子Dialog
    public subDialogSubmit(data: any) {
        this.subDialogVisible = false;
        if (this.subDialogType === ADD) {
            this.tableData.push({ ...data, key: new Date().getTime() });
        } else {
            this.$set(
                this.tableData[this.subCurrentIndex],
                'title',
                data.title
            );
            this.$set(
                this.tableData[this.subCurrentIndex],
                'description',
                data.description
            );
        }
    }
    // 关闭子Dialog
    public subDialogCancel() {
        this.subDialogVisible = false;
        this.clearData();
    }
    // 关闭自己
    public async handleClose() {
        try {
            await this.$confirm('确定要取消吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.cancel(false);
        } catch (e) { }
    }
    // 保存表单
    public submitForm(): void {
        (this.$refs.formInfo as any).validate(async (valid: any) => {
            if (!valid) return;
            if (!this.tableData.length) {
                this.$message.error("至少要添加一条数据！");
                return
            }
            const params = {
                ...this.formInfo,
                subTypes: this.tableData
            };
            if (this.dialogType === ADD) {
                const res = await this.typeManagementService.createType(params);
                if (res) {
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.cancel(true);
                }
            } else if (this.dialogType === EDIT) {
                const res = await this.typeManagementService.updateType(params);
                if (res) {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.cancel(true);
                }
            }
        });
    }
}
