import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class TypeManagementService {
  @Inject(HttpService) private http!: HttpService;
  // 添加
  public createType(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-skill-backend/admin/type/create', param);
  }
  // 编辑
  public updateType(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-skill-backend/admin/type/update', param);
  }
  // 查询
  public getTypeList(param: Dict<any>): Promise<any> {
    return this.http.post('/wecom-skill-backend/admin/type/listPage', param);
  }
  // 删除
  public removeTypeById(id: string | number): Promise<any> {
    return this.http.post(`/wecom-skill-backend/admin/type/delete/${id}`);
  }
  // 子级删除校验
  public itemRemoveCheck(param: Dict<any>): Promise<any> {
    return this.http.post(
      '/wecom-skill-backend/admin/type/checkRemoveSubSkillType',
      param
    );
  }
  // 校验是否关联测评单
  public checkIsAssociated(param: Dict<any>): Promise<any> {
    return this.http.get('/wecom-skill-backend/admin/type/check', param);
  }
}
